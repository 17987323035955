// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

// import "trix"
// import "@rails/actiontext"

$(document).on("scroll", function() {
  const sections = $("[data-section]");
  const menuItems = $("ul a");

  let currentSection = "";

  sections.each(function() {
    const sectionTop = $(this).offset().top - $(window).scrollTop();
    
    if (sectionTop <= 150 && sectionTop >= -150) {
      currentSection = $(this).data("section");
    }
  });

  menuItems.each(function() {
    $(this).removeClass("active");
    
    if ($(this).attr("href").includes(currentSection)) {
      $(this).addClass("active");
    }
  });
});
