import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dkp-menu"
export default class extends Controller {
  static targets = [
    "menu_button",
    "season_text",
    "dkp_seasonal",
    "dkp_total",
    "checkbox",
    "hamburger_line",
    "x",
    "x_line",
  ];
  static classes = ["toggle"];
  connect() {}

  toggle() {
    this.dkp_seasonalTarget.classList.toggle("hidden");
    this.dkp_totalTarget.classList.toggle("hidden");
    this.season_textTarget.classList.toggle("hidden");
    this.menu_buttonTarget.classList.toggle("ring-2");
    this.hamburger_lineTargets.map((x) => x.classList.toggle("translate-x-10"));
    this.xTarget.classList.toggle("translate-x-0");
    this.xTarget.classList.toggle("w-12");
    this.x_lineTargets.map((x, i) => {
      if (i / 1) {
        console.log(i)
        x.classList.toggle("rotate-45");
      } else {
        console.log(i)
        x.classList.toggle("-rotate-45");
      }
    });
  }
}
